/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Descriptions, Divider, Modal, PageHeader, Tag } from 'antd';
import _get from 'lodash/get';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CvAppliedCandidatesApi } from '../../../../core/apis/cvApplied.api';
import { TableBuilder } from '../../../../core/components/table/TableBuilder';
import { TableUtilProvider, useAuthUser, useModal } from '../../../../core/contexts';
import { UserRole } from '../../../../core/models';
import { ECandidateFeedbackStatus, EJobTag, EWorkingTimeType } from '../../../../core/models/cv';
import { adminRouter, saleAdminRouter, saleRouter } from '../../../../core/routes';
import AddTag from '../components/AddTag';
import ViewProfileApplyCv from '../components/ProfileAppyById';
import EditFilePdfCVModal from './components/UpdateFilePdfCVModal';

const BASE_PATH = process.env.REACT_APP_IMAGE_URL;

interface JobDetailByIdProps {}

const ViewDetailCv = () => {
	const profileCvApplyByIdMethods = useModal('profileCvById');
	const modalMethods = useModal<any>('profileCv');
	const [profileApply, setProfileApply] = useState<any>();

	useQuery(
		['profileApply', modalMethods.modalValue?.uuid],
		async () => {
			if (modalMethods.modalValue?.uuid) {
				const res = await CvAppliedCandidatesApi.v1GetProfileApplyById(modalMethods.modalValue?.uuid);
				return res;
			}
		},
		{
			onSuccess: (data) => {
				setProfileApply(data?.data);
			},
			onError: (error: any) => {
				toast.error(error.response.message);
			},
		}
	);

	return (
		<>
			<TableUtilProvider>
				<ViewProfileApplyCv />
			</TableUtilProvider>
			{profileApply?.uuid && (
				<Modal
					title={profileApply?.full_name || ''}
					open={modalMethods.getIsOpen()}
					footer={null}
					centered
					onCancel={() => modalMethods.handleCloseModal()}
					width={800}
				>
					<Descriptions bordered title={''} column={2} layout='vertical'>
						<Descriptions.Item label='Email'>{profileApply?.email}</Descriptions.Item>
						<Descriptions.Item label='Phone'>{profileApply?.phone}</Descriptions.Item>
						<Descriptions.Item label='Gender'>{profileApply?.gender}</Descriptions.Item>
						<Descriptions.Item label='Salary'>
							{profileApply?.salary ? (
								<>
									{profileApply?.salary.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}{' '}
									{profileApply?.salary.type_currency == 'USD' ? 'USD' : 'VND'}
								</>
							):''}
						</Descriptions.Item>
						<Descriptions.Item label='Specialized'>{profileApply?.specialized}</Descriptions.Item>
						<Descriptions.Item label='Experience'>{profileApply?.year_experience}</Descriptions.Item>
						<Descriptions.Item label='Type Job'>
							{profileApply?.type_job?.length > 0 &&
								profileApply?.type_job?.map((values, index) => {
									return `${values}${index !== profileApply?.type_job?.length - 1 ? ', ' : ''}`;
								})}
						</Descriptions.Item>
						<Descriptions.Item label='Cv in Employer'>
							{modalMethods.modalValue?.path ? (
								<a className='ml-2' href={`${BASE_PATH}${modalMethods.modalValue.path}`} target='_blank'>
									View CV PDF
								</a>
							) : (
								<Button
									onClick={() => {
										if (profileApply) {
											profileCvApplyByIdMethods.handleOpenModal(profileApply);
											modalMethods.handleCloseModal();
										}
									}}
								>
									View CV
								</Button>
							)}
						</Descriptions.Item>
						{modalMethods.modalValue?.redactedPath && (
							<Descriptions.Item label='Cv redacted information contact in Employer '>
								<a className='ml-2' href={`${BASE_PATH}${modalMethods.modalValue.redactedPath}`} target='_blank'>
									View CV PDF
								</a>
							</Descriptions.Item>
						)}
					</Descriptions>
				</Modal>
			)}
		</>
	);
};

const ModalCoverLetter = () => {
	const { cvId = '' } = useParams<{ cvId: string }>();
	const coverLetterMethods = useModal<any>('coverLetter');

	const coverLetterUuid = coverLetterMethods.modalValue?.uuid ?? null;

	const coverLetterByIdQuery = useQuery<any>(['cover-letter', cvId, coverLetterUuid], async () => {
		if (!coverLetterUuid) return;
		const { data } = await CvAppliedCandidatesApi.v1GetCoverLetterById(cvId, coverLetterUuid);
		return data;
	});

	return (
		<Modal
			title={''}
			open={coverLetterMethods.getIsOpen()}
			footer={null}
			centered
			onCancel={() => coverLetterMethods.handleCloseModal()}
			width={800}
		>
			<Descriptions bordered title={''} column={1} layout='vertical' key={coverLetterByIdQuery?.data?.uuid}>
				<Descriptions.Item label='Cover Letter'>
					<div
						style={{ whiteSpace: 'pre-line' }}
						dangerouslySetInnerHTML={{
							__html: coverLetterByIdQuery?.data?.content,
						}}
					></div>
				</Descriptions.Item>
			</Descriptions>
		</Modal>
	);
};

export const JobDetailById: React.FC<JobDetailByIdProps> = () => {
	const navigate = useNavigate();
	const { currentUser } = useAuthUser();
	const profileCvMethods = useModal('profileCv');
	const updateProfilePdfMethods = useModal<any>('updateFilePdfCV');
	const coverLetterMethods = useModal<any>('coverLetter');
	const { cvId = '' } = useParams<{ cvId: string }>();
	const [openTag, setOpenTag] = useState<boolean>(false);
	const jobByIdQuery = useQuery<any>(['job-detail', cvId], async () => {
		const { data } = await CvAppliedCandidatesApi.v1JobById(cvId);
		return data;
	});
	const formatWorkExperience = (value: string) => {
		switch (value) {
			case 'not_required':
				return 'Not required';
			case 'less_than_1_year':
				return 'Less than 1 year';
			case 'from_1_to_3_year':
				return 'From 1 to 3 years';
			case 'from_3_to_5_year':
				return 'From 3 to 5 years';
			case 'from_5_to_10_year':
				return 'From 5 to 10 years';
			default:
				return 'More than 10 years';
		}
	};
	const formatFeedbackStatus = (value: number) => {
		switch (value) {
			case ECandidateFeedbackStatus.MATCHED:
				return 'MATCHED';
			case ECandidateFeedbackStatus.PENDING:
				return 'PENDING';
			case ECandidateFeedbackStatus.REJECTED:
				return 'REJECTED';
			default:
				return 'SCREENING';
		}
	};
	React.useEffect(() => {
		document.title = 'Cv Applied - Detail';
	}, []);
	const renderTag = (value: string) => {
		switch (value) {
			case EJobTag.HOT_HERE:
				return 'Hot here';
			case EJobTag.URGENT:
				return 'Urgent';
			default:
				return 'none';
		}
	};

	return (
		<>
			<TableUtilProvider>
				<ViewDetailCv />
				<EditFilePdfCVModal />
				{openTag && jobByIdQuery.data && (
					<AddTag openTag={openTag} setOpenTag={setOpenTag} cvId={cvId} jobData={jobByIdQuery.data?.tag} />
				)}

				<ModalCoverLetter />
			</TableUtilProvider>
			<div className='fade-in mb-4'>
				{jobByIdQuery.data && (
					<>
						<PageHeader
							onBack={() => {
								switch (currentUser?.role) {
									case UserRole.SALE:
										navigate(saleRouter.CvApplied.href());
										break;
									case UserRole.SALE_ADMIN:
										navigate(saleAdminRouter.CvApplied.href());
										break;
									case UserRole.ADMIN_OF_SALE:
										navigate(adminRouter.CvApplied.href());
										break;
									default:
										break;
								}
							}}
							title={jobByIdQuery.data?.title}
							extra={[]}
						/>

						<div className='p-4 mx-4 space-y-4 bg-white rounded-lg'>
							<div className='max-w'>
								<Descriptions bordered title='General information' layout='vertical'>
									<Descriptions.Item label='Title'>{jobByIdQuery.data?.title}</Descriptions.Item>
									<Descriptions.Item label='Salary'>
										{jobByIdQuery.data?.isDesiredSalary ? (
											'Desired Salary'
										) : (
											<>
												{jobByIdQuery.data?.salary?.from.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, '.')} -{' '}
												{jobByIdQuery.data?.salary?.to.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}{' '}
												{jobByIdQuery.data?.salary.type_currency == 'USD' ? 'USD' : 'VND'}{' '}
											</>
										)}
									</Descriptions.Item>
									<Descriptions.Item label='Level'>
										{jobByIdQuery.data?.level?.map((values, index) => {
											return `${values}${index !== jobByIdQuery.data?.level?.length - 1 ? ', ' : ''}`;
										})}
									</Descriptions.Item>
									<Descriptions.Item label='Skill'>
										{jobByIdQuery.data?.skill_term?.length > 0 &&
											jobByIdQuery.data?.skill_term?.map((values, index) => {
												return `${values.skillName}${index !== jobByIdQuery.data?.skill_term?.length - 1 ? ', ' : ''}`;
											})}
									</Descriptions.Item>
									<Descriptions.Item label='Industry'>
										{jobByIdQuery.data?.industries?.length > 0 &&
											jobByIdQuery.data?.industries?.map((values, index) => {
												return `${values.industryname}${
													index !== jobByIdQuery.data?.industries?.length - 1 ? ', ' : ''
												}`;
											})}
									</Descriptions.Item>
									<Descriptions.Item label='No. recruits'>
										{jobByIdQuery.data?.number_vacancies} Personnel
									</Descriptions.Item>
									<Descriptions.Item label='Salary Type'>{jobByIdQuery.data?.salary?.type_salary}</Descriptions.Item>
									<Descriptions.Item label='Type'>
										{jobByIdQuery.data?.type_job?.length > 0 &&
											jobByIdQuery.data?.type_job?.map((values, index) => {
												return `${values}${index !== jobByIdQuery.data?.type_job?.length - 1 ? ', ' : ''}`;
											})}
									</Descriptions.Item>
									<Descriptions.Item label='Gender'>
										{jobByIdQuery.data?.gender?.map((values, index) => {
											return `${values}${index !== jobByIdQuery.data?.gender?.length - 1 ? ', ' : ''}`;
										})}
									</Descriptions.Item>
									<Descriptions.Item label='Work Experience'>
										{formatWorkExperience(jobByIdQuery.data?.work_experience)}
									</Descriptions.Item>
									<Descriptions.Item label='Office Location'>{`${jobByIdQuery.data?.address || ''}, ${
										jobByIdQuery.data?.ward?.name || ''
									}, ${jobByIdQuery.data?.district?.name || ''}, ${
										jobByIdQuery.data?.city?.name || ''
									}`}</Descriptions.Item>
									<Descriptions.Item label='Tag'>
										<Tag style={{ marginBottom: '10px', borderRadius: '5px' }}>{renderTag(jobByIdQuery.data?.tag)}</Tag>
										<Button
											onClick={() => {
												setOpenTag(true);
											}}
										>
											Job Tag
										</Button>
									</Descriptions.Item>
									{jobByIdQuery.data?.type_job?.length > 0 && jobByIdQuery.data?.type_job?.includes('hybrid') ? null : (
										<Descriptions.Item label='Work Time'>
											{jobByIdQuery.data?.workingTimeType === EWorkingTimeType.OFFICE_HOUR ? (
												'Office Hour'
											) : (
												<ul className='capitalize'>
													<li>
														{jobByIdQuery.data?.workingDateOfWeekRange} ({jobByIdQuery.data?.workingHourRange})
													</li>
													{jobByIdQuery.data?.workingTimeNote && <li>{jobByIdQuery.data?.workingTimeNote}</li>}
												</ul>
											)}
										</Descriptions.Item>
									)}
								</Descriptions>
								<Descriptions
									bordered
									title={jobByIdQuery.data.user?.company?.name || ''}
									className='mt-4'
									column={3}
									layout='vertical'
								>
									<Descriptions.Item label='Address'>{`${
										jobByIdQuery.data?.user?.company?.address && `${jobByIdQuery.data?.user?.company?.address}, `
									} ${
										jobByIdQuery.data?.user?.company?.ward ? `${jobByIdQuery.data?.user?.company?.ward?.name}, ` : ''
									} ${
										jobByIdQuery.data?.user?.company?.district
											? `${jobByIdQuery.data?.user?.company?.district?.name}, `
											: ''
									} ${jobByIdQuery.data?.user?.company?.city?.name || ''}`}</Descriptions.Item>
									<Descriptions.Item label='Company size'>
										{jobByIdQuery.data?.user?.company?.companySize}
									</Descriptions.Item>
									<Descriptions.Item label='Industry'>
										{jobByIdQuery.data?.user?.company?.industries?.length > 0 &&
											jobByIdQuery.data?.user?.company?.industries?.map((values, index) => {
												return `${values.industryname}${
													index !== jobByIdQuery.data?.user?.company?.industries?.length - 1 ? ', ' : ''
												}`;
											})}
									</Descriptions.Item>
									<Descriptions.Item label='Overview'>{jobByIdQuery.data?.user?.company?.overview}</Descriptions.Item>
								</Descriptions>
								<Descriptions bordered title='Job Description' className='mt-4'>
									<Descriptions.Item labelStyle={{ display: 'none' }}>
										<div dangerouslySetInnerHTML={{ __html: jobByIdQuery.data?.position }}></div>
									</Descriptions.Item>
								</Descriptions>
								<Descriptions bordered title='Job Requirements' className='mt-4'>
									<Descriptions.Item label='' labelStyle={{ display: 'none' }}>
										<div dangerouslySetInnerHTML={{ __html: jobByIdQuery.data?.skills }}></div>
									</Descriptions.Item>
								</Descriptions>
								<Descriptions bordered title='Benefits' className='mt-4'>
									<Descriptions.Item label='' labelStyle={{ display: 'none' }}>
										{jobByIdQuery.data?.benefits?.map((item: any) => {
											return (
												<Button key={item.benefitId} className='mr-2 mt-2'>
													{item.benefitName}
												</Button>
											);
										})}
									</Descriptions.Item>
								</Descriptions>
								<Descriptions bordered title='Location' className='mt-4'>
									<Descriptions.Item label='' labelStyle={{ display: 'none' }}>
										{jobByIdQuery.data?.areas?.map((item: any) => {
											return (
												<Button key={item.id} className='mr-2 mt-2'>
													{item.name}
												</Button>
											);
										})}
									</Descriptions.Item>
								</Descriptions>
							</div>
							<Divider />
							<div className='max-w mt-4'>
								{jobByIdQuery.data?.submittedProfiles?.length > 0 && (
									<>
										<p className='font-bold'>No. Cv applied</p>
										<TableBuilder
											columns={[
												{
													title: () => <div>No</div>,
													width: 50,
													dataIndex: 'No',
													key: 'No',
													align: 'center',
													render: (text, record, index) => {
														return <>{index + 1}</>;
													},
												},
												{
													title: () => <div>Email</div>,
													width: 80,
													dataIndex: 'profile.email',
													key: 'profile.email',
													align: 'center',
													render: (text, record) => {
														const data = _get(record, 'profile.email', 'Empty');
														return <>{data}</>;
													},
												},
												{
													title: () => <div>Candidate Feedback</div>,
													width: 100,
													dataIndex: 'candidateFeedbackStatus',
													key: 'candidateFeedbackStatus',
													align: 'center',
													render: (text, record) => {
														const data = _get(record, 'candidateFeedbackStatus', 0);
														return <>{formatFeedbackStatus(data)}</>;
													},
												},

												{
													title: () => <div>Cover Letter</div>,
													width: 100,
													dataIndex: 'isSendCoverLetter',
													key: 'isSendCoverLetter',
													align: 'center',
													render: (text, record) => {
														const data = _get(record, 'isSendCoverLetter', 0);
														const userUuid = _get(record, 'profile.userUuid', '');

														return (
															<>
																{data ? (
																	<Button
																		onClick={() => {
																			coverLetterMethods.handleOpenModal({ cvId: cvId, uuid: userUuid });
																		}}
																	>
																		view
																	</Button>
																) : null}
															</>
														);
													},
												},
												{
													title: () => <div>Details</div>,
													width: 50,
													dataIndex: 'details',
													key: 'details',
													align: 'center',
													render: (text, record) => {
														const data: any = record;

														return (
															<>
																{data?.path && (
																	<Button
																		onClick={() => {
																			updateProfilePdfMethods.handleOpenModal({
																				...data.profile,
																				path: data.path,
																				submittedProfileUuid: data?.uuid,
																			});
																		}}
																		className='mr-3'
																	>
																		Edit PDF CV
																	</Button>
																)}
																<Button
																	onClick={() => {
																		profileCvMethods.handleOpenModal({
																			...data.profile,
																			path: data?.path,
																			redactedPath: data?.redactedPath,
																		});
																	}}
																>
																	view
																</Button>
															</>
														);
													},
												},
												{
													title: () => <div>Status Edit PDF CV</div>,
													width: 100,
													dataIndex: 'statusEditPdfCV',
													key: 'statusEditPdfCV',
													align: 'center',
													render: (text, record) => {
														const data = _get(record, 'isRedactedSuccess');
														return <>{data ? 'Edited CV' : 'Unedited CV'}</>;
													},
												},
											]}
											isShowPagination={false}
											data={jobByIdQuery.data?.submittedProfiles || []}
											isLoading={jobByIdQuery.isLoading}
											rowKey='uuid'
										/>
									</>
								)}
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
};
